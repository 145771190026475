ul.info-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px;

  li {
    display: block;
    position: relative;
    padding: 18px 0;

    &:nth-child(even) {
      background-color: #f9f9f9;
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }

    > div {
      word-wrap: break-word;

      &:nth-child(1) {
        float: left;
        line-height: 18px;
        width: calc(45% - 15px);
        max-width: 400px;
        padding-left: 10px;
      }

      &:nth-child(2) {
        float: left;
        width: 50%;
        padding-left: 15px;

        mat-icon {
          float: left;
          margin: -4px 8px 0 0;
        }
      }

      &.notes {
        width: 100%;
        padding: 24px;
        margin-top: 12px;
        background-color: lightyellow;
      }

      ul {
        padding: 0;

        li {
          padding: 12px 0;
        }
      }
    }
  }
}
