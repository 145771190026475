table.mat-table {
  width: 100%;

  .mat-row {
    &:hover {
      background-color: #eee;
      cursor: pointer;
    }

    &.disabled {
      cursor: not-allowed;

      .mat-cell {
        opacity: .54;
      }
    }

    .mat-cell {
      mat-icon:not(.mat-warn) {
        color: rgba(0, 0, 0, .54);
      }
      mat-icon.inProgress {
        color: #0078d7;
      }

      mat-icon.completed {
        color: green;
      }

      mat-icon.cancelled {
        color: orange;
      }

      mat-icon.failed {
        color: red;
      }

      &.online {
        color: green;
      }
      &.offline {
        color: red;
      }

      button:disabled {
        mat-icon {
          color: rgba(0, 0, 0, 0.24)
        }
      }

    }
  }
}
