button[type=submit] {
  margin: 6px;
}

button[type=submit]:enabled {
  background: #18a800;
  color: #fff;
}

mat-spinner {
  margin-left: auto;
  margin-right: auto;
}

