.file-input,
.file-input-sidebar {
  margin: 12px;
  padding: 24px;
  border-radius: 24px;
  border: 2px dashed lightgray;
  color: lightgray;
  font-size: 48px;
  cursor: pointer;

  span {
    &:first-child {
      mat-icon {
        width: 48px;
        height: 48px;
        font-size: 48px;
        float: left;
        margin: 6px;
      }
    }

    &:nth-child(2) {
      font-size: 24px;
    }

    &.selected-filename {
      font-size: 24px;
    }
  }
}

.file-input-sidebar {
  font-size: 18px;

  span {
    &:first-child {
      mat-icon {
        width: 24px;
        height: 24px;
        font-size: 24px;
        float: left;
        margin: 0px 6px;
      }
    }

    &:nth-child(2) {
      font-size: 18px;
    }

    &.selected-filename {
      font-size: 18px;
    }
  }
}
