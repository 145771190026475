.alert {
  color: #fff;
  padding: 12px 24px;
  margin-bottom: 12px;

  mat-icon {
    margin-right: 24px;
  }

  &.warning {
    background-color: #ffa726;
  }

  &.success {
    background-color: #4caf50;
  }

  &.error {
    background-color: #f44336;
  }

  &.info {
    background-color: #607d8b;
  }
}

.no-result {
  font-size: 25px;
  text-align: center;
  padding: 26px 0;
  color: lightgray;

  mat-icon {
    width: 120px;
    height: 120px;
    font-size: 120px;
    margin-bottom: 16px;
  }
}
