html, body {
   height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: rgb(250, 250, 250);
}

div.content {
  margin: 10px;
  height: fit-content;
}

mat-card-header mat-card-title {
  font-size: 32px;
}

@import 'sass/alert';
@import 'sass/material';
@import 'sass/table';
@import 'sass/lists';
@import 'sass/input';
